import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Agent = () => {
  const [agents, setAgents] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [render, setRender] = useState(false);

  useEffect(() => {
    fetchAgents();
    setRender(false);
  }, [render]);

  const fetchAgents = async () => {
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL}/getAgent`);
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const addAgent = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/addAgent`, {
        email,
        password,
      });
      setAgents([...agents, response.data]);
      setEmail('');
      setPassword('');
      setRender(true);
    } catch (error) {
      console.error('Error adding agent:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-12">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg flex flex-col">
        <h2 className="text-2xl font-bold mb-4">Create Agent</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            className="w-full p-2 border border-gray-300 rounded mt-1"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Password</label>
          <input
            type="password"
            className="w-full p-2 border border-gray-300 rounded mt-1"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='flex justify-end'>
        <button
          className="bg-blue-500 text-white  items-center px-4 py-2 rounded hover:bg-blue-600"
          onClick={addAgent}
        >
          Add Agent
        </button>
        </div>
      </div>

      <div className="max-w-4xl mx-auto mt-6 bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Agents List</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">ID</th>
                <th className="py-2 px-4 border-b text-left">Email</th>
                <th className="py-2 px-4 border-b text-left">Password</th>
                
              </tr>
            </thead>
            <tbody>
              {agents.map((agent, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b">{agent.id}</td>
                  <td className="py-2 px-4 border-b">{agent.email}</td>
                  <td className="py-2 px-4 border-b">{agent.password}</td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Agent;