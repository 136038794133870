import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Search } from "lucide-react";

const ChatSidebar = ({ activeChatId, selectChat, socket }) => {
    const [chats, setChats] = useState([]);
    const [newMessages, setNewMessages] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [onlineUsers, setOnlineUsers] = useState(new Set());
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const searchInputRef = useRef(null);
    const chatListRef = useRef(null);

    useEffect(() => {
        const adminId = localStorage.getItem("adminId");
        if (adminId) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/messages/getClientsWithLastMessage/${adminId}`)
                .then((response) => {
                    const loadedChats = response.data.map((item) => ({
                        id: item.chat_id,
                        name: item.name || `User ${item.chat_id}`,
                        lastMessage: item.message_text || "",
                        lastMessageTime: new Date(item.last_message_time),
                    }));
                    setChats(loadedChats);
                })
                .catch((error) => console.error("Failed to load chat users:", error));
        }

        const handleReceiveMessage = (msg) => {
            setChats((prevChats) => {
                const chatExists = prevChats.some((chat) => chat.id == msg.chatId);
                if (chatExists) {
                    return prevChats.map((chat) =>
                        chat.id == msg.chatId
                            ? {
                                  ...chat,
                                  lastMessage: msg.text,
                                  lastMessageTime: new Date(msg.created_at),
                              }
                            : chat
                    ).sort((a, b) => new Date(b.lastMessageTime) - new Date(a.lastMessageTime));
                } else {
                    const newChat = {
                        id: msg.chatId,
                        name: msg.senderName || `User ${msg.chatId}`,
                        lastMessage: msg.text,
                        lastMessageTime: new Date(msg.created_at),
                    };
                    return [...prevChats, newChat].sort((a, b) => new Date(b.lastMessageTime) - new Date(a.lastMessageTime));
                }
            });
            setNewMessages((prevNewMessages) => ({
                ...prevNewMessages,
                [msg.chatId]: true,
            }));
        };

        const handleOnlineStatus = (data) => {
            setOnlineUsers((prev) => {
                const updatedUsers = new Set(prev);
                if (data.isOnline) {
                    updatedUsers.add(data.userId.toString());
                } else {
                    updatedUsers.delete(data.userId.toString());
                }
                return updatedUsers;
            });
        };

        socket.on("userStatus", handleOnlineStatus);
        socket.on("receiveMessage", handleReceiveMessage);

        return () => {
            socket.off("receiveMessage", handleReceiveMessage);
            socket.off("userStatus", handleOnlineStatus);
        };
    }, [socket]);

    // Reset selected index when search query changes
    useEffect(() => {
        setSelectedIndex(-1);
    }, [searchQuery]);

    const handleKeyDown = (e) => {
        const filteredChatsLength = filteredChats.length;

        switch (e.key) {
            case "ArrowDown":
                e.preventDefault();
                setSelectedIndex(prev => 
                    prev < filteredChatsLength - 1 ? prev + 1 : prev
                );
                break;
            case "ArrowUp":
                e.preventDefault();
                setSelectedIndex(prev => prev > 0 ? prev - 1 : prev);
                break;
            case "Enter":
                e.preventDefault();
                if (selectedIndex >= 0 && selectedIndex < filteredChatsLength) {
                    handleSelectChat(filteredChats[selectedIndex]);
                }
                break;
            default:
                break;
        }
    };

    // Scroll selected item into view
    useEffect(() => {
        if (selectedIndex >= 0 && chatListRef.current) {
            const selectedElement = chatListRef.current.children[selectedIndex];
            if (selectedElement) {
                selectedElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        }
    }, [selectedIndex]);

    const getTimeAgo = (timestamp) => {
        const now = new Date();
        const messageTime = new Date(timestamp);
        const diffMs = now - messageTime;
        const diffMinutes = Math.floor(diffMs / (1000 * 60));
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.floor(diffHours / 24);

        if (diffMinutes < 1) return "Just now";
        if (diffMinutes < 60) return `${diffMinutes} min ago`;
        if (diffHours < 24) return `${diffHours} hr ago`;

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        if (
            messageTime.getDate() === yesterday.getDate() &&
            messageTime.getMonth() === yesterday.getMonth() &&
            messageTime.getFullYear() === yesterday.getFullYear()
        ) {
            return "Yesterday";
        }

        return messageTime.toLocaleDateString("en-US", {
            weekday: "short",
            month: "short",
            day: "numeric",
        });
    };

    const truncateLastMessage = (message) => {
        return message.length > 20 ? message.slice(0, 20) + "..." : message;
    };

    const handleSelectChat = (chat) => {
        selectChat(chat);
        setNewMessages((prevNewMessages) => ({
            ...prevNewMessages,
            [chat.id]: false,
        }));
        setSelectedIndex(-1);
        setSearchQuery("");
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredChats = chats.filter((chat) => 
        chat.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        chat.lastMessage.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="w-full sm:w-64 h-[750px] sm:h-full max-sm:w-full bg-gray-100 border-r border-gray-300 flex flex-col">
            <header className="p-3 border-b border-gray-300 bg-dark-blue text-white rounded-lg">
                <h1 className="text-xl font-semibold">Chats</h1>
            </header>
            
            {/* Search Input */}
            <div className="px-4 py-3">
                <div className="relative">
                    <input
                        ref={searchInputRef}
                        type="text"
                        placeholder="Search chats..."
                        value={searchQuery}
                        onChange={handleSearch}
                        onKeyDown={handleKeyDown}
                        className="w-full px-4 py-2 pl-10 bg-white rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
                    />
                    <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
            </div>

            <div className="flex-1 p-3 overflow-y-auto">
                {filteredChats.length > 0 ? (
                    <div ref={chatListRef}>
                        {filteredChats.map((chat, index) => (
                            <div
                                key={`${chat.id}-${index}`}
                                onClick={() => handleSelectChat(chat)}
                                className={`flex items-center mb-4 cursor-pointer p-2 rounded-md transition-colors
                                    ${activeChatId === chat.id ? "bg-gray-300" : ""}
                                    ${index === selectedIndex ? "bg-blue-100" : "hover:bg-gray-200"}
                                `}
                            >
                                <div className="relative w-12 h-12 flex bg-custom-gradient rounded-full mr-3 items-center justify-center text-xl font-semibold">
                                    {chat.name.charAt(0).toUpperCase()}
                                    <span
                                        className={`absolute bottom-0 right-0 w-3 h-3 rounded-full ${
                                            onlineUsers.has(chat.id.toString()) ? "bg-green-500" : "bg-gray-400"
                                        }`}
                                    ></span>
                                </div>
                                <div className="flex-1">
                                    <h2
                                        className={`text-lg font-semibold ${
                                            newMessages[chat.id] ? "text-blue-500" : ""
                                        }`}
                                    >
                                        {chat.name.charAt(0).toUpperCase() + chat.name.slice(1)}
                                    </h2>
                                    <p className="text-gray-600 text-sm flex justify-between">
                                    {truncateLastMessage(chat.lastMessage.slice(0, 7) + (chat.lastMessage.length > 7 ? "..." : ""))}
                                        <span className="text-gray-500 text-xs">{getTimeAgo(chat.lastMessageTime)}</span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-600 mt-4 text-center">No chats found</p>
                )}
            </div>
        </div>
    );
};

export default ChatSidebar;