import React, { useEffect, useState } from "react";
import Banner from "../components/Dashboard/Banner";
import Cards from "../components/Dashboard/Cards";
import chatIconCard from "../assets/chatIconCard.png";
import ticketIconCard from "../assets/ticketc.svg"
import graph4 from "../assets/graph4.png";
import graph from "../assets/graph.png";
import support from "../assets/support.png";
import Logs from "../components/Dashboard/Logs";

const Dashboard = () => {
  const [tickets, setTickets] = useState([]);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${process.env.REACT_APP_API_URL}/tickets/getAllTickets`)
      .then((res) => res.json())
      .then((data) => setTickets(data))
      .catch((error) => console.error("Error fetching tickets:", error));


    // Fetch logs data from the API
    fetch(`${process.env.REACT_APP_API_URL}/logs/getLogs`)
      .then((res) => res.text())
      .then((data) => {
        const logEntries = data.split("\n");
        setLogs(logEntries);
      })
      .catch((error) => console.error("Error fetching logs:", error));
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <Banner />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6 max-w-7xl mx-auto">
        <Cards
          chatIconCard={chatIconCard}
          graph4={graph4}
          name="Live Chat"
          color="bg-card-gradient"
          cardtype="chat"
        />
        <Cards
          chatIconCard={ticketIconCard}
          graph4={graph}
          name="Total Tickets"
          color="bg-red-card-gradient"
          cardtype="ticket"
        />
        <Cards
          chatIconCard={support}
          graph4={graph4}
          name="Total Agents"
          color="bg-gradient-hover"
          cardtype="agent"
        />
        <Cards
          chatIconCard={ticketIconCard}
          graph4={graph}
          name="Pending Tickets"
          color="bg-gradient-amber"
          cardtype="pendingTicket"
        />
      </div>
      <div className="mt-6">
        <div className="overflow-x-auto">
          <h2 className="text-xl font-semibold text-gray-800">Latest Tickets</h2>
          <table className="w-full mt-4 bg-white shadow-md rounded-lg border border-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ticket ID</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subject</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Update</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tickets.map((ticket) => (
                <tr key={ticket.id} className="hover:bg-gray-50">
                  <td className="px-4 py-3 text-sm font-medium text-blue-600">{ticket.ticket_id}</td>
                  <td className="px-4 py-3 text-sm">{ticket.subject}</td>
                  <td className="px-4 py-3">
                    <span className={`px-2 py-1 text-xs rounded-full ${ticket.status === 'open' ? 'bg-yellow-100 text-yellow-800' :
                        ticket.status === 'inprogress' ? 'bg-blue-100 text-blue-800' :
                          'bg-green-100 text-green-800'
                      }`}>
                      {ticket.status}
                    </span>
                  </td>
                  {/* <td className="px-4 py-3">
                          <span className={`px-2 py-1 text-xs rounded-full ${
                            ticket.priority === 'High' ? 'bg-red-100 text-red-800' :
                            ticket.priority === 'Medium' ? 'bg-orange-100 text-orange-800' :
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {ticket.priority}
                          </span>
                        </td> */}
                  <td className="px-4 py-3 text-sm text-gray-500">{ticket.created_at}</td>
                  <td className="px-4 py-3 text-sm text-gray-500">{ticket.updated_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-6">
        <Logs logs={logs} />
      </div>
    </div>
  );
};

export default Dashboard;
