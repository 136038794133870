import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsThreeDots } from "react-icons/bs"; // Importing the three-dots icon

const TicketWindow = (selectedChat) => {
  const [tickets, setTickets] = useState([]);
  const [status, setStatus] = useState("");
  const [showDropdown, setShowDropdown] = useState({});

  useEffect(() => {
    if (selectedChat.id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/tickets/getTickets/${selectedChat.id}`)
        .then((response) => {
          setTickets(
            response.data.map((ticket) => ({
              id: ticket.ticket_id,
              status: ticket.status,
              description: ticket.ticket_message,
              created_at: ticket.created_at,
            }))
          );
        })
        .catch((err) => console.error("Error loading tickets:", err));
    }
  }, [selectedChat.id]);

  const handleChange = async (e, ticketId) => {
    e.preventDefault();
    const newStatus = e.target.value;

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/tickets/updateTicketStatus`, {
        ticket_id: ticketId,
        status: newStatus,
      });

      setStatus(newStatus);
      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.id === ticketId ? { ...ticket, status: newStatus } : ticket
        )
      );

      // Hide dropdown after selecting a status
      setShowDropdown((prev) => ({ ...prev, [ticketId]: false }));
    } catch (error) {
      console.error("Error updating ticket status:", error);
    }
  };

  const toggleDropdown = (ticketId) => {
    setShowDropdown((prev) => ({
      ...prev,
      [ticketId]: !prev[ticketId],
    }));
  };

  return (
    <div className="flex-1 flex flex-col overflow-x-hidden">
      <div className="p-3 bg-dark-blue text-white rounded-lg">
        <h1 className="text-xl font-semibold">
          {selectedChat.name.charAt(0).toUpperCase() +
            selectedChat.name.slice(1, 10).split("@")[0]}
        </h1>
      </div>
      <div className="flex-1 p-4 space-y-2 bg-gray-200 overflow-y-auto">
        {tickets.map((ticket) => {
          // Define tag color based on status
          let statusClasses = "";
          if (ticket.status === "open") statusClasses = "bg-green-500 text-white";
          else if (ticket.status === "inprogress") statusClasses = "bg-yellow-500 text-black";
          else if (ticket.status === "closed") statusClasses = "bg-blue-500 text-white";

          return (
            <div
              key={ticket.id}
              className="relative p-4 bg-white rounded-lg shadow-md max-xsm:w-full sm:w-[270px] md:w-[350px] lg:w-[600px] xl:w-[850px]"
            >
              {/* Three-Dots Icon in Top-Right Corner */}
              <div className="absolute top-2 right-3 cursor-pointer">
                <BsThreeDots size={24} onClick={() => toggleDropdown(ticket.id)} />
              </div>

              

              {/* Status Tag */}
              <span className={`absolute bottom-5 right-3 px-4 py-1 rounded-full text-sm font-semibold ${statusClasses}`}>
                {ticket.status === "open" ? "Open" :
                 ticket.status === "inprogress" ? "In Progress" :
                 "Closed"}
              </span>

              <h3 className="text-lg font-semibold">Ticket Id # {ticket.id}</h3>

              <h2 className="text-lg font-semibold break-words">
                Email:{" "}
                <span className="text-sm text-gray-500 ">
                  {selectedChat.name}
                </span>
              </h2>
              <h1 className="text-lg font-semibold">
                Description:
                <span className="text-sm max-w-[50%] text-gray-500 break-words overflow-hidden ">
                  {ticket.description}
                </span>
              </h1>
              <h1 className="text-lg font-semibold">
                Date:{" "}
                <span className="text-sm text-gray-500">
                  {new Date(ticket.created_at).toLocaleString()}
                </span>
              </h1>

              {/* Dropdown (Visible Only When Clicking Three-Dots) */}
              {showDropdown[ticket.id] && (
                <div className="absolute top-8 right-2">
                  <select
                    value={ticket.status}
                    onChange={(e) => handleChange(e, ticket.id)}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                  >
                    <option value="open">Open</option>
                    <option value="inprogress">In Progress</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TicketWindow;
