import React, { useState, useEffect, useRef } from "react";
import attachment from "../../assets/attachment.png";
import { initiateSocket } from '../../socket';
import axios from "axios";

const ChatWindow = ({ selectedChat, socket, isMobileView, handleBack }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEndChatModalOpen, setIsEndChatModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [file, setFile] = useState(null);
  const [endChatbutton, setEndChatButton] = useState(true);
  const [status, setStatus] = useState({});
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationBody, setNotificationBody] = useState("");
  const containerRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const fileInputRef = useRef(null);
  const AdminId = localStorage.getItem("adminId");

  const generateUniqueFileName = (originalName) => {
    const timestamp = new Date().getTime();
    const name = "myFile";
    const extension = originalName.split(".").pop();
    return `${name}_${timestamp}.${extension}`;
  };

  const toggleEndChatModal = () => {
    setIsEndChatModalOpen(!isEndChatModalOpen);
    setEndChatButton(true);
  };

  useEffect(() => {
    if (selectedChat) {
      console.log("Selected chat:", selectedChat.id);
      axios
        .get(`${process.env.REACT_APP_API_URL}/messages/getAllMessages/${selectedChat.id}`)
        .then((response) => {
          setMessages(
            response.data.map((msg) => ({
              id: msg.message_id,
              sender: msg.sender_type === "admin" ? "admin" : "user",
              text: msg.message_text,
              timestamp: msg.created_at,
              attachment: msg.attachment ? msg.attachment : null,
              generatedAttachment: msg.generatedAttachment
                ? msg.generatedAttachment
                : null,
            }))
          );
        })
        .catch((err) => console.error("Error loading messages:", err));
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/getClientById/${selectedChat.id}`)
      .then((response) => {
        console.log(response.data);
        setUserInfo(response.data);
      })
      .catch((err) => console.error("Error loading user Info:", err));
  }, [selectedChat]);

  useEffect(() => {
    const handleReceiveMessage = (msg) => {
      if (msg.chatId == selectedChat.id) {
        console.log("Received message:", msg);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: msg.message_id,
            sender: msg.sender_type === "admin" ? "admin" : "user",
            text: msg.text,
            timestamp: new Date(msg.created_at),
            attachment: msg.attachment ? msg.attachment : null,
            generatedAttachment: msg.generatedAttachment
              ? msg.generatedAttachment
              : null,
          },
        ]);
      }
    };

    const handleStatus = () => {
      try {
        axios.get(`${process.env.REACT_APP_API_URL}/chat-sessions/active/${selectedChat.id}`)
          .then((response) => {
            console.log("the chat :",response.data);
            setStatus(response.data);
            console.log("Status:", status);
          })
      }
      catch (err) {
        console.error("Error in status:", err);
      }
    }
    if (selectedChat) {
      handleStatus();
    }
    console.log('end chat is ',endChatbutton)

    socket.on("receiveMessage", handleReceiveMessage);
    setEndChatButton(false);
    return () => {
      socket.off("receiveMessage", handleReceiveMessage);
    };
  }, [socket,selectedChat,endChatbutton]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
    socket.emit("typing", { chatId: selectedChat.id, isTyping: true });
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      socket.emit("typing", { chatId: selectedChat.id, isTyping: false });
    }, 1000);
  };

  const confirmEndChat = (e) => {
    e.preventDefault();
    socket.emit("endChat", { chatId: selectedChat.id, senderId: localStorage.getItem("adminId") });
    console.log("End Chat");
    setEndChatButton(true);
    setIsEndChatModalOpen(false);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim() || file) {
      const uniqueFileName = file ? generateUniqueFileName(file.name) : null;
      const msg = {
        text: message.trim(),
        senderId: localStorage.getItem("adminId"),
        receiverId: selectedChat.id,
        senderType: "admin",
        timestamp: new Date(),
        chatId: selectedChat.id,
        attachment: uniqueFileName,
      };
      console.log("Sending message:", msg);
      socket.emit("sendMessage", msg);
      socket.emit("typing", { chatId: selectedChat.id, isTyping: false });

      setMessage("");
      setFile(null);

      if (file) {
        const formData = new FormData();
        formData.append("myFile", file, uniqueFileName);
        formData.append("chatId", selectedChat.id);
        formData.append("senderId", localStorage.getItem("adminId"));
        formData.append("senderType", "admin");
        formData.append("receiverId", selectedChat.id);
        formData.append("timestamp", new Date().toISOString());
        formData.append("attachment", uniqueFileName);

        try {
          axios
            .post(`${process.env.REACT_APP_API_URL}/upload/sendFile`, formData)
            .then((response) => {
              console.log("File upload response:", response.data);
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  id: response.data.messageId,
                  sender: "admin",
                  text: message,
                  timestamp: new Date(),
                  attachment: uniqueFileName,
                },
              ]);
            })
            .catch((err) => {
              console.error("Error uploading file:", err);
            });
        } catch (err) {
          console.error("Error in file upload:", err);
        }
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: new Date().getTime(),
            sender: "admin",
            text: message,
            timestamp: new Date(),
            attachment: null,
            generatedAttachment: null,
          },
        ]);
      }
    }
  };

  const scrollToBottom = () => {
    const scrollContainer = containerRef.current;
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    socket.on("acceptnotification", (data) => {
      const { body } = data;
      console.log("Notification:", body);
      setNotificationBody(body);
      setIsNotificationModalOpen(true);
    });
  }, [socket]);

  const formatMessageDate = (timestamp) => {
    const messageDate = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return "TODAY";
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return "YESTERDAY";
    } else {
      return messageDate
        .toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })
        .toUpperCase();
    }
  };

  const groupMessagesByDate = (messages) => {
    const groups = {};
    messages.forEach((message) => {
      const date = formatMessageDate(message.timestamp);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
    });
    return Object.entries(groups);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };

  const closeNotificationModal = () => {
    setIsNotificationModalOpen(false);
  };

  return (
    <div className="flex-1 flex flex-col max-h-[calc(100vh-10rem)] max-sm: h-[calc(100vh-10rem)]">
      {/* Chat title section */}
      <div className="p-3 bg-dark-blue text-white rounded-lg items-center justify-between flex ">
        {isMobileView && (
          <button onClick={handleBack} className="mr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
        )}
        <h1
          className="text-xl font-semibold cursor-pointer inline-block"
          onClick={toggleModal}
        >
          {selectedChat.name.charAt(0).toUpperCase() +
            selectedChat.name.slice(1)}
        
        <span className="ml-4">
          {status.status == "ASSIGNED" ? (
            <span className="  rounded-full text-xs px-3 py-1 font-semibold bg-green-500 text-white">Assigned</span>
          ) : (
            <span className="rounded-full text-xs px-3 py-1 font-semibold bg-red-500 text-white">Closed</span>
           )
          }
          </span>

        
        </h1>
        { status.admin_id == AdminId &&
        <button onClick={()=>toggleEndChatModal()}
          className="mr-2 bg-dark-red rounded px-2 text-lg">
          End Chat
        </button>
}
      </div>

      {isEndChatModalOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white rounded-lg shadow-xl p-6">
            <h2 className="text-xl font-semibold mb-4">Do you really want to end chat?</h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={confirmEndChat}
                className="bg-dark-red text-white px-4 py-2 rounded"
              >
                End Chat
              </button>
              <button
                onClick={toggleEndChatModal}
                className="bg-gray-300 text-black px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isNotificationModalOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white rounded-lg shadow-xl p-6">
            <h2 className="text-xl font-semibold mb-4">Notification</h2>
            <p>{notificationBody}</p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={closeNotificationModal}
                className="bg-dark-red text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <>
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-[400px] sm:max-w-lg ${isModalOpen ? "animate-slideIn" : ""
                  }`}
              >
                <div className="bg-dark-blue px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex justify-center sm:items-start gap-2">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3
                        className="text-2xl ml-[50px] font-semibold leading-6 border border-2 rounded-full w-16 h-16 p-4 text-center text-white bg-custom-gradient"
                        id="modal-title"
                      >
                        {userInfo.name.slice(0, 1).toUpperCase()}
                      </h3>
                      <div className="mt-8 flex flex-col gap-2">
                        <p className="text-sm text-white">
                          <span className="font-bold ">Name :</span>{" "}
                          {userInfo.name}
                        </p>
                        <p className="text-sm text-white">
                          <span className="font-bold ">Email :</span>{" "}
                          {userInfo.email}
                        </p>
                        <p className="text-sm text-white">
                          <span className="font-bold ">Contact :</span>{" "}
                          {userInfo.contact}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex mr-[-17px] w-full justify-center rounded-md bg-dark-red px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={toggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Messages display section */}
      <div
        ref={containerRef}
        className="flex-1 p-4 space-y-2 bg-gray-200 overflow-y-auto"
      >
        {groupMessagesByDate(messages).map(([date, groupMessages]) => (
          <div key={date} className="space-y-1">
            <p className="text-xs text-gray-500 uppercase text-center">
              {date}
            </p>
            {groupMessages.map((message) => (
              <div
                key={message.id + message.timestamp}
                className={`flex ${message.sender === "admin" ? "justify-end" : "justify-start"} mt-2`}
              >
                <div
                  className={`max-w-full sm:max-w-md px-2 py-2 rounded-lg shadow ${message.sender === "admin" ? "bg-[#2532d6] text-white" : "bg-white text-gray-700"
                    }`}
                  style={{ wordWrap: "break-word", overflowWrap: "break-word" }}
                >
                  <p>{message.text}</p>
                  {message.attachment && (
                    <div className="mt-2">
                      <a
                        href={`${process.env.REACT_APP_API_URL}/uploads/${message.attachment}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-blue-300 hover:text-blue-100 underline"
                      >
                        {message.attachment}
                      </a>
                    </div>
                  )}
                  <p className="text-[9px] text-right mt-1">
                    {new Date(message.timestamp).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Message input */}
      <div className="bg-[#f2f2f2] p-3 flex items-center">
        <form className="flex w-full" onSubmit={handleSendMessage}>
          <input
            type="text"
            placeholder="Type a message"
            className="flex-1 p-2 rounded-lg border border-gray-300"
            value={message}
            onChange={handleInputChange}
          />
          <button
            type="submit"
            className="ml-2 p-2 bg-dark-blue text-white rounded-lg"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;