import React, { useEffect, useState } from "react";
import logo from "../assets/logo4xp.png";
import notificationIcon from "../assets/bellb.png";
import userIcon from "../assets/userb.png";
import hamburger from "../assets/hamburger.png";
import { CgProfile } from "react-icons/cg";
import { IoNotificationsOutline } from "react-icons/io5";
import { useNotifications } from "./NotificationContext";
import { IoIosSearch } from "react-icons/io";
import { socket } from "../socket";
import { useNavigate } from "react-router-dom";
import notificationBell from "../assets/notification.wav";

const Header = ({ toggleSidebar }) => {
  const { addNotification } = useNotifications();
  const { notifications, clearNotifications } = useNotifications();
  const [showNotifications, setShowNotifications] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleNotification = (notification) => {
      console.log("Notification received:", notification);
      
      addNotification(notification);
      const audio = new Audio(notificationBell);
      audio.play();
    };

    const handleNewChatRequest = (data) => {
      console.log("New chat request received:", data);
      const notification = {
        type: 'chat_request',
        title: 'New Chat Request',
        body: `${data.senderName}: ${data.message}`,
        chatId: data.chatId,
        clientId: data.clientId
      };
      addNotification(notification);
      const audio = new Audio(notificationBell);
      audio.play();
    };

    socket.on("notification", handleNotification);
    socket.on("newChatRequest", handleNewChatRequest);

    return () => {
      socket.off("notification", handleNotification);
      socket.off("newChatRequest", handleNewChatRequest);
    };
  }, [addNotification]);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleClearNotifications = () => {
    clearNotifications();
    setShowNotifications(false);
  };

  const handleProfileClick = () => {
    setShowProfile(!showProfile);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminId");
    navigate("/login");
  };

  const handleAcceptChat = (notification) => {
    const adminId = localStorage.getItem('adminId');
    console.log('Accepting chat', { adminId, notification });
    if (!adminId) return;

    socket.emit('acceptChat', {
      chatId: notification.chatId,
      adminId: adminId
    });

    // Navigate to chat window
    // navigate(`/chat/${notification.chatId}`);
    
    // Remove this notification
    const updatedNotifications = notifications.filter(
      n => !(n.type === 'chat_request' && n.chatId === notification.chatId)
    );
    clearNotifications();
    updatedNotifications.forEach(n => addNotification(n));
    
    setShowNotifications(false);
  };

  return (
    <>
      <div className="flex top-0 items-center justify-between p-2 bg-gray-100">
        <div className="flex items-center ml-5">
          <img
            src={logo}
            alt="logo"
            className="w-20 p-1 h-auto cursor-pointer"
          />
        </div>
        {/* <div className="relative flex-grow flex justify-center">
          <input
            type="search"
            placeholder="Search..."
            className="w-[300px] ml-10 max-sm:ml-3 rounded-lg h-10
             p-3 max-sm:hidden bg-gray-300 pl-10 " // Added padding-left for the icon
          />
          <div className="absolute mr-[215px] mt-[11px]">
            <IoIosSearch className="w-5 h-5 text-gray-500 max-sm:hidden" />
          </div>
        </div>   */}
        <div className="flex items-center pr-2">
          <div>
            <IoNotificationsOutline
              onClick={handleNotificationClick}
              className="w-10 h-auto p-2 cursor-pointer bg-dark-red hover:text-black text-white rounded-full mr-3"
            />

            {notifications.length > 0 && (
              <span className="absolute top-2 right-24 bg-dark-red text-white rounded-full w-6 h-6 flex items-center justify-center">
                {notifications.length}
              </span>
            )}
          </div>

          <CgProfile
            onClick={handleProfileClick}
            className="w-10 h-auto p-2  cursor-pointer bg-dark-red hover:text-black text-white rounded-full"
          />
          <img
            src={hamburger}
            alt="hamburger"
            onClick={toggleSidebar}
            className="w-10 ml-5 md:hidden lg:hidden min-md:hidden hover:cursor-pointer"
          />
        </div>
        {showNotifications && (
          <div className="absolute right-4 top-16 bg-white border border-gray-300 rounded-lg w-80 h-64 shadow-lg flex flex-col justify-between">
            <ul className="overflow-y-auto">
              {notifications.map((notification, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-gray-100 border-b border-gray-200"
                >
                  <div className="flex flex-col">
                    <span className="font-semibold">{notification.title}</span>
                    <span>{notification.body}</span>
                    {notification.type === 'chat_request' && (
                      <button
                        onClick={() => handleAcceptChat(notification)}
                        className="mt-2 bg-dark-red text-white px-4 py-1 rounded-md hover:bg-red-700"
                      >
                        Accept Chat
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <button
              onClick={handleClearNotifications}
              className="w-full bg-header-gradient text-white py-2 rounded-b-lg"
            >
              Clear Notifications
            </button>
          </div>
        )}
        {showProfile && (
          <div className="absolute right-4 top-16 bg-white border border-gray-300 rounded-lg w-28 h-22  shadow-lg flex flex-col">
            <ul className="overflow-y-auto">
              <li className="p-2 border-b border-gray-200 hover:bg-gray-100">
                <button>View Profile</button>
              </li>
            </ul>
            <ul className="overflow-y-auto">
              <li className="p-2 border-b border-gray-200 hover:bg-gray-100">
                <button onClick={handleLogout}>Logout</button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
