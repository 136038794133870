import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Banner from "../Banner";
import { format } from "date-fns";
import { AiOutlineDelete } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [suggestedArticles, setSuggestedArticles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchArticle();
  }, [id]);

  useEffect(() => {
    if (article && article.category) {
      fetchSuggestedArticles();
    }
    
  }, [article]);

  const handleEdit = () => {
    navigate(`/home/article/edit/${article.id}`);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/article/deleteArticle/${id}`
      );
      navigate("/home/knowledgebase");
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  const fetchArticle = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/getArticleById/${id}`
      );
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        const fetchedArticle = data[0];
        const formattedArticle = {
          ...fetchedArticle,
          content: fetchedArticle.content
            ? fetchedArticle.content.replace(
                /(\*\*.*?\*\*)/g,
                "<strong>$1</strong>"
              )
            : "",
        };

        setArticle(formattedArticle);
      } else {
        console.error("No article found");
      }
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };

  if (!article) return <div className="text-center">Loading...</div>;

  const fetchSuggestedArticles = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/getArticleByCategory/${article.category}/${id}`
      );
      const data = await response.json();
      const suggestedArticles = data.slice(0, 3);
      setSuggestedArticles(suggestedArticles);
    } catch (error) {
      console.error("Error fetching suggested articles:", error);
    }
  };

  return (
    <>
      <div>
        <Banner title="4X Portal Articles" />
      </div>
      <div className="w-full min-h-screen flex flex-col items-center mt-6 px-4 md:px-8 lg:px-16">
        <div className="w-full flex flex-col lg:flex-row gap-4 lg:justify-center">
          <div className="w-full lg:w-full max-w-4xl bg-gray-100 shadow-lg rounded-lg p-8">
            <div className="flex flex-col md:flex-row justify-between items-start mb-6">
              <h1 className="text-3xl md:text-4xl font-bold leading-tight">
                {article.title}
              </h1>
              <div className="mt-4 md:mt-0 md:ml-4">
                <div className="flex">
                  <FaRegEdit
                    onClick={handleEdit}
                    className="text-xl flex-row cursor-pointer text-blue-500 hover:scale-110 translate-x-1 transition-all"
                  />
                  <AiOutlineDelete
                    onClick={() => setIsModalOpen(true)}
                    className="text-xl cursor-pointer ml-2 mb-1 text-red-500 hover:scale-110 translate-x-1 transition-all"
                  />
                </div>
                <h2 className="text-xl font-bold mb-2">Tags</h2>
                <ul className="flex flex-wrap">
                  {article?.tags?.split(",").map((tag, index) => (
                    <li key={index} className="mr-2 mb-2">
                      <span className="bg-blue-200 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                        {tag.trim()}
                      </span>
                    </li>
                  )) || <p>No tags available</p>}
                </ul>
              </div>
            </div>
            <div className="font-semibold text-gray-600 mb-4 flex flex-col md:flex-row justify-start space-y-2 md:space-y-0 md:space-x-4">
              <span>
                Category:{" "}
                <span className="text-blue-700">{article.category}</span>
              </span>
              <span>
                Author: <span className="text-blue-700">{article.author}</span>
              </span>
              <span>Published on: {format(new Date(article.date), "yyyy-MM-dd")}</span>
            </div>
            <div
              className="text-lg leading-relaxed text-justify"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </div>

          <div className="w-full lg:w-1/3 max-w-sm bg-gray-50 shadow-lg rounded-lg p-8">
            <h2 className="text-2xl font-bold mb-4 mt-4 m-2">
              Suggested Articles
            </h2>
            <div className="space-y-4">
              {suggestedArticles.map((suggested) => (
                <Link
                  to={`/home/article/getArticleById/${suggested.id}`}
                  key={suggested.id}
                >
                  <div className="p-4 border rounded-md shadow-sm hover:shadow-lg transition-shadow duration-300">
                    <h3 className="text-xl font-bold">{suggested.title}</h3>
                    <p className="text-gray-600 text-sm">
                      {suggested.category}
                    </p>
                    <p className="text-gray-700">{suggested.excerpt}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this article?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ArticleDetail;
