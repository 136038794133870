import React, { useState, useEffect, useCallback, memo } from "react";

const Cards = ({ chatIconCard, name, graph4, color, cardtype }) => {
  const [dataCount, setDataCount] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl = "";
        if (cardtype === "chat") {
          apiUrl = `${process.env.REACT_APP_API_URL}/messages/getClients/1`;
        } else if (cardtype === "ticket") {
          apiUrl = `${process.env.REACT_APP_API_URL}/tickets/getUsersWithTickets`;
        } else if (cardtype === "agent") {
          apiUrl = `${process.env.REACT_APP_API_URL}/getAgent`;
        } else if (cardtype === "pendingTicket") {
          apiUrl = `${process.env.REACT_APP_API_URL}/tickets/getPendingTickets`;
        }

        if (apiUrl) {
          const response = await fetch(apiUrl);
          const data = await response.json();
          setDataCount(Array.isArray(data) ? data.length : 0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataCount(0);
      }
    };

    fetchData();
  }, []);

  // const renderValue = useCallback(() => {
  //   switch (cardtype) {
  //     case "chat":
  //       return totalChats;
  //     case "ticket":
  //       return totalTickets;
  //     case "agent":
  //       return totalAgents;
  //     default:
  //       return 0;
  //   }
  // }, [cardtype, totalChats, totalTickets, totalAgents]);

  return (
    <div className={`rounded-2xl transform transition-all duration-300 hover:scale-105`}>
      <div className={`flex flex-col w-[350px] h-[220px] ${color} mt-5 ml-5 rounded-xl`}>
        <div className="flex flex-row items-center mt-14 ml-4">
          <img src={chatIconCard} alt="chatIconCard" className="w-8 h-8" />
          <div className="flex flex-col">
            <p className="ml-2 text-xl font-bold">{name}</p>
            <p className="ml-2">{dataCount}</p>
          </div>
        </div>
        <div className="flex items-center">
          <img src={graph4} alt="graph4" />
        </div>
      </div>
    </div>
  );
};

export default Cards;



