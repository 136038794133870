import io from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_API_URL;
// const SOCKET_URL = "https://backend.4xp.chat";
const adminId = localStorage.getItem("adminId");
const adminType = 'admin'; // 'admin' or 'superadmin'

export const socket = io(SOCKET_URL, {
  autoConnect: true,
  reconnection: true
});

export const initiateSocket = () => {
  if(!socket.connected) {
    socket.connect();
  }

  socket.on("connect", () => {
    if(adminId){
      console.log(`Connected to socket server with adminId: ${adminId}`);
      socket.emit("register", { userId: adminId, userType: adminType });
    } else {
      console.log(`Connected to socket server without adminId`);
    }
  });
  socket.on('chatAssigned', (data) => {
    console.log('Chat assigned event received:', data);
});

  socket.on("disconnect", () => {
    console.log("Disconnected from socket server");
  });
  
  socket.on('userStatus', (data) => {
    console.log('User status received:', data);
    // Update the UI to show the user status
   
});
  socket.on('onlineUsers', (userId) => {
    console.log("Online users:", userId);
  });


  socket.on("connect_error", (error) => {
    console.error("Connection error:", error);
  });

  socket.on("reconnect_attempt", () => {
    console.log("Reconnecting.....");
  });

  // Handle reconnection attempts
  socket.on("reconnect", () => {
    console.log("Reconnected to server. Re-registering admin...");
    socket.emit("register", { userId: adminId, userType: adminType });
});
};

export const registerAdmin = () => {
  const currentAdminId = localStorage.getItem("adminId");
  if (currentAdminId && socket.connected) {
    console.log(`Registering admin with ID: ${currentAdminId}`);
    socket.emit("register", { userId: currentAdminId, userType: adminType });
  }
};

