import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const userRole =  localStorage.getItem('role');
  console.log('userRole', userRole);
  

  const handleSave = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchTerm) {
      fetchdata();
    } else {
      setSearchResults([]);
      setShowDropdown(false);
    }
  }, [searchTerm]);

  const fetchdata = async () => {
    setLoading(true);
    setShowDropdown(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/Search/${searchTerm}`
      );
      const data = await response.json();
      setSearchResults(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-5">
      {/* Heading */}
      <p className=" md:text-3xl font-semibold p-2 text-gray-700 sm:text-xl mt-6">
        Hi Admin, we’re here to help.
      </p>

      {/* Search Bar */}
      <div className="relative w-[70%]">
        <input
          className="p-3 bg-gray-100 border border-gray-300 rounded-lg w-full pl-12 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="text"
          placeholder="How can we help?"
          onChange={handleSave}
          value={searchTerm}
        />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />

        {/* Dropdown for Search Results */}
        {showDropdown && (
          <div className="absolute top-full left-0 w-full bg-white border border-gray-200 rounded-lg shadow-lg mt-1 z-10 max-h-60 overflow-y-auto">
            {loading ? (
              <p className="p-3 text-center text-gray-500">Loading...</p>
            ) : searchResults.length > 0 ? (
              searchResults.map((result) => (
                <div
                  key={result.id}
                  className="p-3 hover:bg-blue-50 cursor-pointer border-b last:border-b-0"
                  onClick={() =>
                    navigate(`/home/article/getArticleById/${result.id}`)
                  }
                >
                  <p className="text-lg font-medium text-blue-600">
                    {result.title}
                  </p>
                  <p
                    className="mt-2 text-gray-700 line-clamp-2"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 2,
                    }}
                    dangerouslySetInnerHTML={{ __html: result.content }}
                  />
                </div>
              ))
            ) : (
              <p className="p-3 text-center text-gray-500">No results found</p>
            )}
          </div>
        )}
      </div>

      {/* New Article Button */}
      {(userRole === 'admin' || userRole === 'superadmin') && (
      <Link to="/home/newarticle">
        <button className="p-2 mt-4 hover:bg-blue-600 rounded-lg bg-blue-700 text-white font-medium shadow-md">
          New Article
        </button>
      </Link>
      )}
    </div>
  );
};

export default SearchBar;
