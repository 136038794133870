import React from "react";
import Banner from "../components/Banner";
import SearchBar from "../components/knowledgebase/SearchBar";
import Faqs from "../components/knowledgebase/Faqs";
import Article from "../components/knowledgebase/Article";

const KnowledgeBase = () => {
  return (
    <div className="flex flex-col">
      <div>
        {/* <p className="text-3xl mb-3">Knowledge Base</p> */}
        {/* <Banner title="4X Portal Knowledge Base" /> */}
      </div>
      <div>
        <SearchBar />
      </div>
      <div>
        <Article/>
      </div>
      <div>
        <Faqs />
      </div>
    </div>
  );
};

export default KnowledgeBase;
