import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Search } from "lucide-react";

const TicketSidebar = ({ activeChatId, selectChat }) => {
  const [ticketUsers, setTicketUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newMessages, setNewMessages] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const searchInputRef = useRef(null);
  const ticketListRef = useRef(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/tickets/getUsersWithTickets`)
      .then((response) => {
        setTicketUsers(response.data);
      })
      .catch((error) =>
        console.error("Failed to load users with tickets:", error)
      );
  },[]);

  // Reset selected index when search query changes
  useEffect(() => {
    setSelectedIndex(-1);
  }, [searchQuery]);

  // Scroll selected item into view
  useEffect(() => {
    if (selectedIndex >= 0 && ticketListRef.current) {
      const selectedElement = ticketListRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [selectedIndex]);

  const handleKeyDown = (e) => {
    const filteredUsersLength = filteredTicketsUsers.length;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < filteredUsersLength - 1 ? prev + 1 : prev
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case "Enter":
        e.preventDefault();
        if (selectedIndex >= 0 && selectedIndex < filteredUsersLength) {
          const selectedUser = filteredTicketsUsers[selectedIndex];
          handleSelectChat({
            type: "ticket",
            id: selectedUser.email,
            name: selectedUser.email,
          });
        }
        break;
      default:
        break;
    }
  };

  const handleSelectChat = (chat) => {
    selectChat(chat);
    setNewMessages((prevNewMessages) => ({
      ...prevNewMessages,
      [chat.id]: false,
    }));
    setSelectedIndex(-1);
    setSearchQuery("");
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTicketsUsers = ticketUsers.filter((ticketUser) =>
    ticketUser.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-64 max-sm:w-32 bg-gray-100 border-r border-gray-300 h-full flex flex-col">
      <header className="p-3 border-b border-gray-300 bg-dark-blue text-white rounded-lg mr-[1px]">
        <h1 className="text-xl font-semibold">Tickets</h1>
      </header>

      {/* Search Input */}
      <div className="px-4 py-3">
        <div className="relative">
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search tickets..."
            value={searchQuery}
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            className="w-full px-4 py-2 pl-10 bg-white rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="p-3 mt-4 overflow-y-auto h-[700px] md:h-[750px]">
        {filteredTicketsUsers.length > 0 ? (
          <div ref={ticketListRef}>
            {filteredTicketsUsers.map((user, index) => (
              <div
                key={user.email}
                onClick={() =>
                  handleSelectChat({
                    type: "ticket",
                    id: user.email,
                    name: user.email,
                  })
                }
                className={`flex items-center mb-4 cursor-pointer p-2 rounded-md transition-colors
                  ${activeChatId === user.email ? "bg-gray-300 text-black" : ""}
                  ${index === selectedIndex ? "bg-blue-100" : "hover:bg-gray-200 hover:text-black"}
                `}
              >
                <div className="w-12 h-12 max-sm:hidden bg-header-gradient rounded-full mr-3 flex items-center justify-center text-xl font-semibold">
                  {user.email.charAt(0).toUpperCase()}
                </div>
                <div className="flex-1">
                  <h2 className="text-lg max-sm:text-sm max-sm:break-words font-semibold">
                    {user.email.charAt(0).toUpperCase() + user.email.slice(1, 10).split("@")[0]}
                  </h2>
                  <p className="text-gray-600">View Tickets</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-600 mt-4 text-center">No tickets found</p>
        )}
      </div>
    </div>
  );
};

export default TicketSidebar;