import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom'; // Import Link for navigation
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Article = () => {
  const [testArticles, setTestArticles] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/article/getarticles`);
    const data = await response.json();
    
    // Format content for rendering
    const formattedData = data.map(article => ({
      ...article,
      content: article.content.replace(/(\*\*.*?\*\*)/g, '<strong>$1</strong>') // Basic example for Markdown-like syntax
    }));
    
    setTestArticles(formattedData);
  };

  const isSlider = testArticles.length > 6;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    rows: 2,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive:[
      {
        breakpoint:450,
        settings:{
          slidesToShow:1,
          slidesToScroll:1,
          rows:2,
          nextArrow:null,
          prevArrow:null,
        }
      }
    ]
    
  };

  return (
    <div className=" max-w-6xl mx-auto my-10 relative">
      {isSlider ? (
        <Slider {...settings}>
          {testArticles.map((article, index) => (
            <div key={index}>
              <Link to={`/home/article/getArticleById/${article.id}`}> {/* Link to the article detail page */}
                <ArticleCard article={article} />
              </Link>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {testArticles.slice(0, 6).map((article, index) => (
            <div className="m-4" key={index}>
              <Link to={`/home/article/getArticleById/${article.id}`}> {/* Link to the article detail page */}
                <ArticleCard article={article} />
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Custom Arrow Component for Slider Navigation
const CustomArrow = ({ className, onClick, direction }) => (
  <div
    className={`${className} bg-gray-300 hover:bg-gray-400 rounded-full w-10 h-10 flex items-center justify-center absolute top-1/2 transform -translate-y-1/2 z-10 ${direction === "prev" ? "left-0" : "right-0"}`}
    onClick={onClick}
  >
    <span className="text-lg font-bold text-gray-600">
      {direction === "prev" ? "‹" : "›"}
    </span>
  </div>
);

const ArticleCard = ({ article }) => (
  <div className= " bg-gray-100 border-2 hover:border-blue-700 hover:scale-105 hover:transition-all m-[24px] p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out text-center h-64 flex flex-col justify-between">
    <h3 className="text-xl font-bold mt-4 mb-2">{article.title}</h3>
    <p className="mt-2 overflow-hidden" dangerouslySetInnerHTML={{ __html: article.content }} />
  </div>
);

export default Article;
