import React, { useState, useEffect } from "react";
import Banner from "../Banner";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const NewArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [articleData, setArticleData] = useState({
    title: "",
    content: "",
    author: "",
    category: "",
    date: new Date().toISOString().split("T")[0],
    Tags: "",
    length: 0,
  });

  const [showPopup, setShowPopup] = useState(false);
  const categories = ["Forex", "Investing", "Blockchain", "MT-5", "Informative"];

  useEffect(() => {
    if (id) {
      fetchArticleData(id);
    }
  }, [id]);

  const fetchArticleData = async (articleId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/article/getArticleById/${articleId}`
      );
      const data = response.data[0];

      if (data) {
        setArticleData({
          title: data.title,
          content: data.content,
          author: data.author,
          category: data.category,
          Tags: data.tags,
          date: data.date.split("T")[0],
          length: data.content.length,
        });
      }
    } catch (error) {
      console.error("Error fetching article for edit:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticleData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (content, delta, source, editor) => {
    const textLength = editor.getText().trim().length;
    setArticleData((prev) => ({
      ...prev,
      content: content,
      length: textLength,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        // Update Existing Article
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/article/UpdateArticle/${id}`,
          articleData
        );

        if (response.status === 200) {
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 3000);
          navigate(`/home/article/getArticleById/${id}`);
        } else {
          alert("Failed to update article");
        }
      } else {
        // Insert New Article
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/article/insertarticles`,
          articleData
        );

        if (response.status === 201) {
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 1500);
          setTimeout(() => navigate(`/home/knowledgebase`), 1500);
          // setArticleData({
          //   title: "",
          //   content: "",
          //   author: "",
          //   category: "",
          //   Tags: "",
          //   date: new Date().toISOString().split("T")[0],
          //   length: 0,
          // });

        
        } 
        else {
          alert("Article not added");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Banner title={id ? "Edit Article" : "Create Article"} />
      <div className="max-w-5xl mx-auto p-6 mt-8 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-2/3">
            <input
              className="bg-gray-100 border border-gray-300 rounded-lg m-2 p-3 w-full text-gray-700 focus:ring-2 focus:ring-blue-400 focus:outline-none shadow-sm"
              type="text"
              placeholder="Title"
              name="title"
              value={articleData.title}
              onChange={handleChange}
            />
            <ReactQuill
              theme="snow"
              value={articleData.content}
              onChange={handleContentChange}
              className="rounded-lg m-2 w-full mb-14 h-96 text-gray-700 md:mb-6 lg:mb-2"
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  [{ align: [] }],
                  ["clean"],
                ],
              }}
            />
          </div>
          <div className="w-full lg:w-1/3 flex flex-col gap-4 items-center">
            <input
              className="bg-gray-100 border border-gray-300 rounded-lg m-2 p-3 w-full text-gray-700 focus:ring-2 focus:ring-blue-400 focus:outline-none shadow-sm"
              type="text"
              placeholder="Author"
              name="author"
              value={articleData.author}
              onChange={handleChange}
            />
            <select
              className="bg-gray-100 border border-gray-300 rounded-lg m-2 p-3 w-full text-gray-700 focus:ring-2 focus:ring-blue-400 focus:outline-none shadow-sm"
              name="category"
              value={articleData.category}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <input
              className="bg-gray-100 border border-gray-300 rounded-lg m-2 p-3 w-full text-gray-700 focus:ring-2 focus:ring-blue-400 focus:outline-none shadow-sm"
              type="text"
              placeholder="Tags"
              name="Tags"
              value={articleData.Tags}
              onChange={handleChange}
            />
            <input
              className="bg-gray-100 border border-gray-300 rounded-lg m-2 p-3 w-full text-gray-700 focus:ring-2 focus:ring-blue-400 focus:outline-none shadow-sm"
              type="text"
              placeholder="Date"
              name="date"
              value={articleData.date}
              onChange={handleChange}
            />
            <div className="flex flex-col items-start w-full m-2">
              <label className="text-gray-600 mb-1" htmlFor="length">
                Document Length:
              </label>
              <input
                className="bg-gray-100 border border-gray-300 rounded-lg p-3 w-full text-gray-700 focus:outline-none shadow-sm"
                type="text"
                name="length"
                value={articleData.length}
                readOnly
              />
            </div>
            <button
              className="text-white w-24 p-2 m-4 hover:bg-blue-600 rounded-lg bg-blue-700"
              onClick={handleSubmit}
            >
              {id ? "Update" : "Submit"}
            </button>
          </div>
        </div>
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity">
            <div className="bg-white p-5 rounded-lg flex items-center gap-2 shadow-lg transform transition-all scale-105">
              <svg
                className="w-6 h-6 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <p>{id ? "Article Updated Successfully!" : "Article Submitted Successfully!"}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewArticle;
