import React, { useState, useEffect } from "react";
import ChatSidebar from "../components/LiveChat/ChatSidebar";
import ChatWindow from "../components/LiveChat/ChatWindow";
import { socket } from "../socket";

const LiveChat = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [activeChatId, setActiveChatId] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const selectChat = (chat) => {
    setActiveChatId(chat.id);
    setSelectedChat(chat);
  };

  const handleBack = () => {
    setSelectedChat(null);
  };

  return (
    <div className="overflow-hidden flex flex-col ">
      {/* <p className="text-3xl mb-3">Live Chat</p> */}
      <div className="flex flex-col sm:flex-row w-full rounded-lg bg-white mt-5 max-h-[calc(100vh-8rem)] ">
        {!selectedChat || !isMobile ? (
          <div className="w-full sm:w-64 flex-shrink-0">
            <ChatSidebar
              activeChatId={activeChatId}
              selectChat={selectChat}
              socket={socket}
            />
          </div>
        ) : null}
        {selectedChat ? (
          <div className={`flex-1 ${isMobile ? 'w-full' : 'flex'}`}>
            <ChatWindow
              selectedChat={selectedChat}
              socket={socket}
              isMobileView={isMobile}
              handleBack={handleBack}
            />
          </div>
        ) : (
          <div className="flex-1 overflow-x-hidden flex flex-col">
            <div className="flex w-[100%] bg-dark-blue h-[53px]  rounded-lg"></div>
            <div className="flex items-center justify-center mt-16">
              <span >Select a user to see Chats.</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveChat;
