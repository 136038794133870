import React from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    
    if(!token){
       return <Navigate to='/login'/>;
    }
    else{
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() /1000;

        if (decodedToken.exp <currentTime){
            localStorage.removeItem('token');
            localStorage.removeItem('adminId');
          return  <Navigate to='/login'/>
        }
        return children;
    }




    // return token ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;