import React, { useState } from 'react';

const Logs = ({ logs = [] }) => {
  const [filter, setFilter] = useState('');
  const [expanded, setExpanded] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 10;

  // Filter logs based on search input
  const filteredLogs = logs.filter(log => 
    log.toLowerCase().includes(filter.toLowerCase())
  );

  // Calculate the logs to display based on the current page
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

  // Toggle expanded state for a log entry
  const toggleExpand = (index) => {
    setExpanded(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Format timestamp if present in log
  const formatLog = (log) => {
    const timestampRegex = /^\[\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?Z?\]/;
    if (timestampRegex.test(log)) {
      const parts = log.split('] ');
      const timestamp = parts[0] + ']';
      const content = parts.slice(1).join('] ');
      
      return (
        <>
          <span className="text-gray-500 font-mono text-xs">{timestamp} </span>
          <span>{content}</span>
        </>
      );
    }
    return log;
  };

  // Determine log entry color based on content
  const getLogClass = (log) => {
    const logLower = log.toLowerCase();
    if (logLower.includes('error') || logLower.includes('fail')) {
      return 'border-l-4 border-red-500 bg-red-50';
    } else if (logLower.includes('warn')) {
      return 'border-l-4 border-yellow-500 bg-yellow-50';
    } else if (logLower.includes('success')) {
      return 'border-l-4 border-green-500 bg-green-50';
    } else if (logLower.includes('info')) {
      return 'border-l-4 border-blue-500 bg-blue-50';
    }
    return 'border-l-4 border-gray-300';
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total pages
  const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

  // Calculate the range of page numbers to display
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    const maxPageNumbersToShow = 3;
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`mx-1 px-3 py-1 border rounded ${currentPage === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="mt-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-800">Latest Logs</h2>
        <div className="relative">
          <input
            type="text"
            placeholder="Filter logs..."
            className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg border border-gray-200">
        {currentLogs.length === 0 ? (
          <div className="p-6 text-center text-gray-500">
            {filter ? "No logs match your filter" : "No logs available"}
          </div>
        ) : (
          <ul className="divide-y divide-gray-200">
            {currentLogs.map((log, index) => (
              <li 
                key={index} 
                className={`px-4 py-3 hover:bg-gray-50 transition-colors duration-150 ${getLogClass(log)}`}
              >
                <div 
                  className="flex items-start cursor-pointer"
                  onClick={() => toggleExpand(index)}
                >
                  <div className="flex-1 text-sm">
                    {expanded[index] || log.length < 100 ? (
                      <div className="whitespace-pre-wrap font-mono">{formatLog(log)}</div>
                    ) : (
                      <div className="font-mono truncate">{formatLog(log)}</div>
                    )}
                  </div>
                  {log.length >= 100 && (
                    <button className="ml-2 text-blue-500 hover:text-blue-700">
                      {expanded[index] ? '−' : '+'}
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="mt-2 text-xs text-gray-500">
        Showing {currentLogs.length} of {filteredLogs.length} logs
      </div>

      <div className="mt-4 flex justify-center items-center">
        <button
          className="mx-1 px-3 py-1 border rounded bg-white text-gray-700"
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {renderPageNumbers()}
        <button
          className="mx-1 px-3 py-1 border rounded bg-white text-gray-700"
          onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Logs;